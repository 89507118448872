import '../fonts/Icons.woff';
import '../fonts/Inter-Black.woff2';
import '../fonts/Inter-Bold.woff2';
import '../fonts/Inter-ExtraBold.woff2';
import '../fonts/Inter-ExtraLight.woff2';
import '../fonts/Inter-Light.woff2';
import '../fonts/Inter-Medium.woff2';
import '../fonts/Inter-Regular.woff2';
import '../fonts/Inter-SemiBold.woff2';
import '../fonts/Inter-Thin.woff2';

import '../fonts/DarkerGrotesque-Black.woff2';
import '../fonts/DarkerGrotesque-Bold.woff2';
import '../fonts/DarkerGrotesque-ExtraBold.woff2';
import '../fonts/DarkerGrotesque-Light.woff2';
import '../fonts/DarkerGrotesque-Medium.woff2';
import '../fonts/DarkerGrotesque-Regular.woff2';
import '../fonts/DarkerGrotesque-SemiBold.woff2';

import '../fonts/Baskervville-Regular.woff2';
import '../fonts/Baskervville-Italic.woff2';

import '../fonts/IBMPlexMono-Bold.woff2';
import '../fonts/IBMPlexMono-BoldItalic.woff2';
import '../fonts/IBMPlexMono-ExtraLight.woff2';
import '../fonts/IBMPlexMono-ExtraLightItalic.woff2';
import '../fonts/IBMPlexMono-Italic.woff2';
import '../fonts/IBMPlexMono-Light.woff2';
import '../fonts/IBMPlexMono-LightItalic.woff2';
import '../fonts/IBMPlexMono-Medium.woff2';
import '../fonts/IBMPlexMono-MediumItalic.woff2';
import '../fonts/IBMPlexMono-Regular.woff2';
import '../fonts/IBMPlexMono-SemiBold.woff2';
import '../fonts/IBMPlexMono-SemiBoldItalic.woff2';
import '../fonts/IBMPlexMono-Thin.woff2';
import '../fonts/IBMPlexMono-ThinItalic.woff2';

// Fonts
import '../sass/main.scss';

import { GradientAnimation } from './classes/GradientAnimation';

(() => {
    const $pageHeadings = document.querySelectorAll('.page-heading');

    const initPageHeadingBackgrounds = () => {
        if (!$pageHeadings) return;

        $pageHeadings.forEach($pageHeading => {
            const $canvas = document.createElement('canvas');
            $canvas.classList.add('page-heading__background');
            $canvas.width = 1000;
            $canvas.height = 1000;

            $pageHeading.appendChild($canvas);

            new GradientAnimation($canvas);
        });
    };

    initPageHeadingBackgrounds();
})();
